<template>
    <div class='add_card'>
        <Form 
            :formItems="formItems" 
            :button="button" 
            @chooseBank="chooseBank"
            @submit="submit"
            @chooseArea="chooseArea"/>
        <van-popup v-model:show="show" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                :title="title"
                :options="showOptions"
                @close="show = false"
                @change="onChange($event)"
                />
        </van-popup>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import Form from '../../components/form.vue'
import { Cascader, Popup } from 'vant'
import { getBanksAndCitys, getCity, addBankCard } from '../../utils/api'
import { inputIsAllFill, toast, goBack } from '../../utils/util'
import 'vant/es/popup/style'
import 'vant/es/cascader/style'

export default {
    name: 'addBankCard',
    components: { Form, 'van-cascader': Cascader, 'van-popup': Popup },
    setup() {
         const state= reactive({
             show: false,
             bankOptions: [
                //  {
                //      text: '工商银行1', 
                //      value: '3301',
                //  },
             ],
             cityOptions: [],
             formItems: [
                {label: '选择银行', value: '', tip: '', eventName: 'chooseBank', showArrow: true, type: 'button'},
                {label: '银行地址', value: '', tip: '', eventName: 'chooseArea', showArrow: true, type: 'button'},
                {label: '支行名称', value: '', type: 'input'},
                {label: '银行卡号', value: '', type: 'input'}
            ],
            title: '请选择银行',
            selecetdBank: {},
            selectedCity: {},
            chooseBank: true,
            button: {label: '绑定', eventName: 'submit'},
            cascaderValue: '',
        })
        const showOptions = computed(() => {
            return state.chooseBank ? state.bankOptions : state.cityOptions
        })
        function chooseBank() {
            state.title = '请选择银行'
            state.chooseBank = true
            state.show = true
        }
        function chooseArea() {
            state.title = '请选择银行所在地址'
            state.chooseBank = false
            state.show = true
        }
        async function loadData() {
            try {
                const result = await getBanksAndCitys()
                if (result.code == 0) {
                    result.data.banks.forEach(ele => {
                        state.bankOptions.push({text: ele.name, value: ele.level})
                    })
                    result.data.proviceList.forEach(ele => {
                        state.cityOptions.push({text: ele.name, value: ele.id, children: []})
                    })
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        async function loadCity(event) {
            try {
                const result = await getCity(event[0].value)
                if (result.code == 0) {
                    console.log(result.data)
                    result.data.forEach(ele => {
                        event[0].children.push({text: ele.name, value: ele.id})
                    })
                }

            } catch(e) {
                console.error(e)
            }
        }
        function onFinish(item) {
            if (state.chooseBank) {
                state.selecetdBank = item.selectedOptions
            } else {
                state.selectedCity = item.selectedCity
            }
            state.show = false
        }
        // 弹出框选择事件
        async function onChange(event) {
            try {
                if (state.chooseBank) {
                    state.selecetdBank = event.selectedOptions
                    state.formItems[0].value = state.selecetdBank[0].text
                    state.show = false
                } else {
                    if(event.selectedOptions.length == 1) {
                        loadCity(event.selectedOptions)
                    } else {
                        state.selectedCity = event.selectedOptions
                        state.formItems[1].value = `${state.selectedCity[0].text}${state.selectedCity[1].text}`
                        state.show = false
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 提交添加银行卡
        async function submit() {
            try {
                 if (!inputIsAllFill(state.formItems)) {
                    // toast('未填写完整，不能提交')
                }
                const params = {
                    accountNo: state.formItems[3].value,
                    bank: state.selecetdBank[0].value,// 银行卡类表里的level字段
                    province: state.selectedCity[0].text,
                    city: state.selectedCity[1].text,
                    subbranch: state.formItems[2].value,
                    provinceId: state.selectedCity[1].value,
                }
                const result = await addBankCard(params)
                if (result.code == 0) {
                    toast('添加银行卡成功')
                    goBack()
                }
            } catch(e) {
                console.error(e)
            }
        }
        return {
            ...toRefs(state),
            chooseBank,
            chooseArea,
            showOptions,
            onFinish,
            onChange,
            submit
        }
    },
}
</script>
<style lang='scss' scoped>
</style>